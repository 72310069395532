@import "../../index.scss";

.split-page {
    display: flex;
    flex-direction: row;
    letter-spacing: 0.3em;
    color: $primaryTextColour;
    position: absolute;
    height: 100%;
    width: 100%;
    overflow: hidden;
    text-align: center;

    &--page {
        position: relative;
        flex: 1 1 50%;
        height: 100%;

        .copy-container {
            display: flex;
            flex-direction: column;
            align-content: center;
            justify-content: center;
        }

        .title {
            @include font-primary;
            font-size: 32px;
        }
    
        .statement {
            @include font-secondary;
            font-size: 16px;
        }
    }

    &--square-left {
        .split-page--page-left {
            flex: 1 0 calc( (1080px - $coreMenuHeight) * 1.1);
        }
    }

    &--square-right {
        .split-page--page-right {
            flex: 1 0 calc( (1080px - $coreMenuHeight) * 1.1);
        }
    }
}

.floorplan {
    background-color: $secondaryBkgColour;

    & &--copy-container {
        display: flex;
        flex-direction: row;
        align-content: center;
        justify-content: center;
        flex-wrap: wrap;
        
        height: 100%;
    }

    &--copy-container > * {
        flex-basis: 100%;
    }

    &--copy-container &--title {
        display: block;
        @include font-primary;
        margin-bottom: 1em;
        text-align: center;
    }

    &--statments-container {
        padding-bottom: 60px;
    }

    &--copy-container &--statement {
        display: block;
        @include font-primary;
        margin-bottom: 0.8em;
        font-size: 16px;
        text-align: center;
    }

    &--image {
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        transform: translateY(-50%);
    }

    &--layer-image-container {
        position: relative;
        width: 100;
        height: 100%;

        > img {
            position: absolute;
            top: 50%;
            left: 0;
            width: 100%;
            transform: translateY(-50%);
        }
    }

    &--button-container {
        position: relative;
        width: 100%;
        padding-bottom: 100px;
        text-align: center;
    }

    &--button {
        display: inline-block;
        position: relative;
        padding: 15px 30px;
        flex-basis: auto;

        @include font-primary;
        font-size: 18px;
        color: inherit;
        text-transform: uppercase;
        letter-spacing: .3em;

        opacity: 0.65;

        &::after {
            content: '';
            position: absolute;
            width: 0px;
            left: 50%;
            bottom: 0;
            transform: translateX(-50%);
            opacity: 0;
            border-bottom: 1px solid currentColor;

            transition: all 0.3s ease-in-out;
        }

        &__active {
            @extend .floorplan--button;
            opacity: 1;

            &::after {
                opacity: 1;
                width: 150px;
            }
        }

        &:hover {
            @extend .floorplan--button__active;
        }

    }

    &--copy-container {
        padding: 0;
        background: none;
        color: $secondaryTextColour;
    }
    
    .floorplan__smaller &--copy-container {
        padding: 23%;
    }
    
    &--statements-container {
        padding: 23%;
    }
    
    &--button-container {
        padding: 0;
        padding-top: 100px;
    }
}

.floorplan-image-shift .floorplan {
    &--image-background {
        left: -85px;
    }
    
    &--layer-image {
        left: -85px;
    }
}

.floorplan-image-nudge .floorplan {
    &--image-background {
        left: -40px;
    }
    
    &--layer-image {
        left: -40px;
    }
}
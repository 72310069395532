@import "../../index.scss";

.team {
    &--page {
        top: calc($coreMenuHeight + $secondaryMenuHeight);
        height: calc(100% - ($coreMenuHeight + $secondaryMenuHeight));
    }

    &--copy-contents {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 100%;
        padding-left: 100px;

        background: $bkgColour;
        background: $bkgGradient;
    }
    
    &--about {
        padding: 5.5%;

        &-title {
            @include font-primary;
        }
        &-para {
            @include font-secondary-light;
        }
        &-italic {
            @include font-secondary-italic;    
        }
    }

    &--divider {
        height: 90%;
        width: 1px;
        background-color: white;
    }
    
    &--stats {
        flex: 1 0 45%;
        padding: 1%;
        color: white;

        &-emphisis {
            @include font-primary;
            font-size: 56px;
            font-weight: 500;
            display: block;
            margin: 50px 0 0 0.3em;
        }
        &-subtitle {
            @include font-secondary;
            display: block;
            margin-bottom: 50px;
        }
    }

    &--image-container {
        height: 100%;
        background: $bkgColour;
    }

    &--image {
        display: block;
        width: auto;
        height: 100%;
        margin-left: 0;
    }
}
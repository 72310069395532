
@import '~sanitize.css'; //https://github.com/csstools/sanitize.css


/*$bkgColour: #002b47;*/
$sandColour:#fff0dc;
$merlotColour:#8a1438;
$darkColour:#1f1d1b;

$bodyBkgColour: $darkColour;
$bkgColour: $merlotColour;
$secondaryBkgColour: $sandColour;
$bkgGradient: linear-gradient(95deg, rgba(138,20,55,1) 0%, rgba(138,20,55,1) 20%, rgba(142,27,60,1) 44%, rgba(146,32,65,1) 61%, rgba(152,42,73,1) 77%, rgba(171,74,99,1) 100%);

$menuTextColour: #FFF;
$primaryTextColour: $sandColour;
$secondaryTextColour: $merlotColour;

$menuEdgeSpacing: 150px;
$menuSpacing: calc( ( 100% - ($menuEdgeSpacing *1.75) ) / 7 );
$coreMenuZIndex: 10;
$secondaryMenuZIndex: 9;
$enlargedZIndex: 20;
$coreMenuHeight: 100px;
$secondaryMenuHeight: 43px;

@font-face {
  font-family: 'Gilroy';
  font-weight: 500;
  src: url('../Radomir Tinkov - Gilroy-Medium.otf');
}

@font-face {
  font-family: 'Gilroy';
  font-weight: 400;
  src: url('../Radomir Tinkov - Gilroy-Regular.otf');
}

@font-face {
  font-family: 'Gilroy';
  font-weight: 300;
  src: url('../Radomir Tinkov - Gilroy-Light.otf');
}

@font-face {
  font-family: 'Gilroy';
  font-weight: 300;
  src: url('../Radomir Tinkov - Gilroy-Light.otf');
}

@mixin font-primary {
	font-family: 'Gilroy', Verdana, Geneva, sans-serif;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.3em;
  text-transform: uppercase;
  margin: 0 0 0.2em 0.3em;
}

@mixin font-primary-light {
	font-family: 'Gilroy', Verdana, Geneva, sans-serif;
  font-style: normal;
  font-weight: 300;
  letter-spacing: 0.3em;
  text-transform: uppercase;
  margin: 0 0 0.2em 0.3em;
}

@mixin font-secondary {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 400;
  letter-spacing: unset;
  text-transform: none;
}

@mixin font-secondary-light {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 300;
  letter-spacing: 1px;
  text-transform: none;
}

@mixin font-secondary-italic {
  font-family: 'Gilroy';
  font-style: italic;
  font-weight: 300;
  letter-spacing: 1px;
  text-transform: none;
}


// @mixin font-secondary {
// 	font-family: 'Times New Roman', Times, serif;
// 	font-weight: normal;
// 	font-style: normal;
// }


body {
  background: $bodyBkgColour;
  overflow: hidden;
}


button { 
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  &:focus {
    outline: none;
  }
}


.shared--loading {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  &:before {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100px;
    margin: -50px 0px 0px -50px;
    content: "loading...";
    color: $primaryTextColour;
    text-align: center;
    @include font-primary();
    font-size: 20px;
  }
  &:after {
    position: absolute;
    top: 50%;
    left: 50%;
    content: " ";
    width: 12px;
    height: 12px;
    border-radius: 50%;
    display: block;
    margin: -6px 0px 0px -6px;
    color: #FFF;
    box-sizing: border-box;
    animation: animloader 1.5s linear infinite;
  }
}

  
@keyframes animloader {
  0% {
    box-shadow: 14px 0 0 -2px,  38px 0 0 -2px,  -14px 0 0 -2px,  -38px 0 0 -2px;
  }
  25% {
    box-shadow: 14px 0 0 -2px,  38px 0 0 -2px,  -14px 0 0 -2px,  -38px 0 0 2px;
  }
  50% {
    box-shadow: 14px 0 0 -2px,  38px 0 0 -2px,  -14px 0 0 2px,  -38px 0 0 -2px;
  }
  75% {
    box-shadow: 14px 0 0 2px,  38px 0 0 -2px,  -14px 0 0 -2px,  -38px 0 0 -2px;
  }
  100% {
    box-shadow: 14px 0 0 -2px,  38px 0 0 2px,  -14px 0 0 -2px,  -38px 0 0 -2px;
  }
}


.shared--load-container {
  position: absolute;
  display: none;
  width: 100%;
  height: 100%;
  background: $bodyBkgColour;
  opacity: 0;
}


.shared--img-fill {
  width: 100%;
  height: auto;
}


.fade-enter {
	opacity: 0;
}
.fade-enter-active {
	opacity: 1;
	transition: opacity 300ms;
}
.fade-exit {
	opacity: 1;
}
.fade-exit-active {
	opacity: 0;
	transition: opacity 300ms;
}

@import "../../index.scss";

$fontSize: 14px;
$buttonHeight: $fontSize * 1.5;

$buttonOffset: 0px;

.multipage--transform-container {
    position: relative;
    top: 100px;
    height: calc( 100% - $coreMenuHeight );
}

.multipage--bkg {
    position: relative;
}


.multipage--frg {
    position: absolute;
    left: 0;
    top: 43px;
}


.multipage--menu {
    position: absolute;
    top: -1px;
    width: 100%;
    height: $secondaryMenuHeight;
    background: $primaryTextColour;
    // &:before {
    //     position: absolute;
    //     top: 0px;
    //     left: -10px;
    //     width: 12px;
    //     height: 100%;
    //     background: $primaryTextColour;
    //     content: '';
    // }

    ul {
        margin: 0;
        padding: 0;
        width: 100%;

        li {
            display: inline-block;
            position: relative;
        }
    }
}

.multipage--btn-container {
    display: inline-block;
    width: 0;
    margin-left: $menuSpacing;
    padding-top: calc( ($secondaryMenuHeight - $buttonHeight) / 2 ) + $buttonOffset;
    padding-bottom: calc( ($secondaryMenuHeight - $buttonHeight) / 2 ) - $buttonOffset;

    &:first-of-type {
        margin-left: $menuEdgeSpacing;
    }
}

.multipage--btn {
    width: 150px;
    @include font-primary;
    letter-spacing: 0.1em;
    font-size: $fontSize;
    font-weight: 500;
    text-transform: uppercase;
    color: $secondaryTextColour;
    opacity: 0.6;
    transform: translate(-50%);
    img {
        position: absolute;
        left: 0;
        top: 0px;
    }

    &:hover {
        opacity: 1;
    }
}


    .multipage--btn__active {
        @extend .multipage--btn;
        opacity: 1;
    }

@import "../../index.scss";

.hotspot {
    position: absolute;

    &--lighting-btn {
        z-index: 2;
        position: absolute;
        width: 100%;
        min-width: 160px;
        height: 40px;
        border-radius: 20px;
        padding: 7px 10px 7px 45px;
        color: white;
        @include font-secondary;
        font-size: 16px;
        background-color: $bkgColour;
        background-image: url(./light-off.png);
        background-repeat: no-repeat;
        background-position: 4px 4px;
        background-size: 32px 32px;
        text-align: left;

        &__active {
            @extend .hotspot--lighting-btn;
            background-image: url(./light-on.png);
        }
    }
}

.amenities--hotspot {
    position: absolute;
}


    .amenities--hotspot__btn {
        width: 40px;
        height: 40px;
        background: url(./icon--image.png) no-repeat;
        background-size: 40px 40px;
    }


    .amenities--hotspot__tmb {
        display: none; //show with JS
        position: absolute;

        &, &.bottom-right {
            left: 45px;
            top: 0px;
        }

        &.bottom-left {
            right: 45px;
            left: unset;
            top: 0px;
        }

        &.top-right {
            left: 45px;
            bottom: 0px;
            top: unset;
        }

        &.top-left {
            right: 45px;
            left: unset;
            bottom: 0px;
            top: unset;
        }
    } 